import { UIStore } from "./utils/store";
import Home from './pages/home';
import {Helmet} from "react-helmet";

function App() {

  const isDarkMode = UIStore.useState(s => s.isDarkMode);

  return <>
      {(isDarkMode && <Helmet>
	       <link rel="stylesheet" href="css/new-skin/classic-skin.css" />
	       <link rel="stylesheet" href="css/template-dark/dark.css" />
      </Helmet>)}
      <Home />
    </>;

}

export default App;
